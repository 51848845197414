
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@root/material.module';
import { RoutingModule } from '@root/routing.module';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer.component';
import { HeaderComponent } from './header.component';
import { NavigationComponent } from './navigation.component';
import { SidebarHideDirective } from './directives/sidebar.hide.directive';
import { SidebarSwitchDirective } from './directives/sidebar.switch.directive';
import { NavItemComponent } from './nav-item.component';
import { SidebarComponent } from './sidebar.component';
import { ProfileComponent } from './profile.component';

/**
 * Angular module to encapsulate the declarable objects of the outer layout.
 * This module contains all declarable objects for the header, footer and sidebar.
 */
@NgModule({
  declarations: [
    SidebarSwitchDirective,
    SidebarHideDirective,
    SidebarComponent,
    NavItemComponent,
    NavigationComponent,
    ProfileComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RoutingModule,
    SharedModule,
    MaterialModule,
    FontAwesomeModule,
    FlexLayoutModule
  ],
  exports: [
    SidebarSwitchDirective,
    SidebarHideDirective,
    SidebarComponent,
    NavItemComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class LayoutModule { }
