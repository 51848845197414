import { EventEmitter, Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { AppEvent } from './types/events';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Type<T> = new (...args: any[]) => T;

/**
 * Angular Service to emit event and subscribe to global {@link EventEmitter} of this app.
 */
@Injectable({ providedIn: 'root' })
export class EventService {

  /**
   * Attribute to emit and subscribe to any {@link AppEvent} of this application.
   * This Attribute can not accessed directly. It is needed to call the method {@link emit}
   * to ensure every emitted event is inherited from {@link AppEvent}.
   * This {@link EventEmitter} is return as {@link Observable} to restrict emitting of events without the use of
   * this {@link EventService}.
   */
  private _events: EventEmitter<AppEvent>;

  /**
   * @constructor
   * Constructor to initialize private event attribute with a new instance of {@link EventEmitter}.
   */
  constructor() {
    this._events = new EventEmitter();
  }

  /**
   * Getter for private {@link EventEmitter} as {@link Observable}
   */
  get events() {
    return this._events as Observable<AppEvent>;
  }

  /**
   * Method to obtain a Observable filtered by the given type of a {@link AppEvent}.
   *
   * @param {Type<T>} type type attribute to filter emit event
   * @returns a Observable which supplies a value of the given application event
   */
  get<T extends AppEvent>(type: Type<T>): Observable<T> {
    return this.events.pipe(filter(event => event instanceof type)) as Observable<T>;
  }

  /**
   * Delegates given event to EventEmitter.
   * Prevents emit of an event without an event object.
   *
   * @param {AppEvent} event {@link AppEvent} to emit as payload
   */
  emit(event: AppEvent): void {
    this._events.emit(event);
  }
}
