<mat-nav-list [fxLayout]="menu ? 'row' : 'column'">
    <mat-accordion displayMode="flat" [fxLayout]="menu ? 'row' : 'column'">
        <div mat-list-item>
            <nav-item *ngIf="!menu" home [link]="'/home'" [label]="'home'" [icon]="'home'"></nav-item>
            <hexagon>
                <a *ngIf="menu" class="logo" [routerLink]="'/home'"><img src="assets/logo.png" alt="logo" fxHide.xs width="100%" height="100%" /></a>
            </hexagon>
        </div>
        <mat-expansion-panel [class.mat-elevation-z0]="true" [class.menu]="menu" dense projects-menu [hideToggle]="menu">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon *ngIf="!menu">code</mat-icon><span class="nav-caption">projects</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense class="menu-content" [class.bg-grey]="menu">
                <nav-item followguide [link]="'/projects/followguide'" [label]="'followguide'" [icon]="'live_tv'"></nav-item>
                <nav-item swordcoat [link]="'/projects/swordcoat'" [label]="'swordcoat'" [icon]="'swords'" [symbols]="true"></nav-item>
                <nav-item upcoming [link]="'/projects/upcoming'" [label]="'upcoming'" [icon]="'rocket_launch'"></nav-item>
            </mat-nav-list>
        </mat-expansion-panel>
        <mat-expansion-panel [class.mat-elevation-z0]="true" [class.menu]="menu" dense tools-menu [hideToggle]="menu">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon *ngIf="!menu">code</mat-icon><span class="nav-caption">tools</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense class="menu-content" [class.bg-grey]="menu">
                <nav-item gitlab [link]="gitlabUrl" [label]="'gitlab'" [icon]="'merge'" [external]="true"></nav-item>
                <nav-item sonarcloud [link]="sonarcloudUrl" [label]="'sonarcloud'" [icon]="'donut_small'" [external]="true">
                </nav-item>
                <nav-item semgrep [link]="semgrepUrl" [label]="'semgrep'" [icon]="'security'" [external]="true"></nav-item>
            </mat-nav-list>
        </mat-expansion-panel>
        <nav-item imprint [link]="'assets/legals.html'" [label]="'imprint & legals'" [icon]="'gavel'" [external]="true" [menu]="menu">
        </nav-item>
    </mat-accordion>
</mat-nav-list>