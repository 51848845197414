import { Component } from '@angular/core';

/**
 * Angular component to render the navigation sidebar.
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {}
