import { Directive, HostBinding, OnInit } from '@angular/core';
import { AnchorComponent } from '@shared/components/anchor.component';

/**
 * Angular directive for the selector '[anchor]' to set the target to attribute 'href',
 * value 'noopener' to attribute 'rel' and value '_blank' to attribute 'target'.
 */
@Directive({ selector: '[anchor]' })
export class AnchorDirective implements OnInit {

  /**
   * {@link HostBinding} for attribute 'href'
   */
  @HostBinding('attr.href') href?: string;

  /**
   * {@link HostBinding} for attribute 'rel'
   */
  @HostBinding('attr.rel') rel?: string;

  /**
   * {@link HostBinding} for attribute 'target'
   */
  @HostBinding('attr.target') target?: string;

  /**
   * @constructor
   * Constructor to inject host component of this directive.
   *
   * @param {AnchorComponent} host host component of this directive
   */
  constructor(private host: AnchorComponent) { }

  /**
   * @inheritDoc {@link OnInit.ngOnInit}
   *
   * Method sets the values to the injected attributes.
   */
  ngOnInit(): void {
    this.href = this.host.link;
    this.rel = 'noopener';
    this.target = '_blank';
  }
}
