
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@root/material.module';
import { AnchorComponent } from './components/anchor.component';
import { HcCardComponent } from './components/hc-card.component';
import { HexagonComponent } from './components/hexagon.component';
import { RatioBoxComponent } from './components/ratio-box.component';
import { AnchorDirective } from './direcitve/anchor.directive';

/**
 * Angular module to provide shared directives or components.
 */
@NgModule({
  declarations: [
    HcCardComponent,
    AnchorComponent,
    AnchorDirective,
    HexagonComponent,
    RatioBoxComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  exports: [
    HcCardComponent,
    AnchorComponent,
    AnchorDirective,
    HexagonComponent,
    RatioBoxComponent
  ]
})
export class SharedModule {}
