import { Component, Input } from '@angular/core';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {

  @Input() menu = false;

  /**
   * Url of the gitlab group from the company
   */
  readonly gitlabUrl: string = 'https://gitlab.com/hazelcomb';

  /**
   * Url of the code quality tool 'sonarcloud'
   */
  readonly sonarcloudUrl: string = 'https://sonarcloud.io/organizations/hazelcomb/projects';

  /**
   * Url of the security complicance tool 'semgrep
   */
  readonly semgrepUrl: string = 'https://semgrep.dev/orgs/hazelcomb/findings';
}
