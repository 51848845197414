/**
 * Abstract class of application based events
 */
export abstract class AppEvent {

  /**
   * @constructor
   * Default constructor to initialize the {@link event} attriute.
   * @param {Event} event the value of the event
   */
  constructor(readonly event: Event) { }
}

/**
 * Event to trigger when the sidebar should be toggled
 */
export class SidebarToggleEvent extends AppEvent { }

/**
 * Event to trigger when the sidebar closes
 */
export class SidebarCloseEvent extends AppEvent { }
