import { Component, forwardRef, Input } from '@angular/core';
import { AnchorComponent } from '@shared/components/anchor.component';


/**
 * Angular component for a anchor item inside the navigation sidebar.
 * A provider for {@link AnchorComponent} is specified to inject this component to
 * {@link AnchorDirective}.
 */
@Component({
  selector: 'nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.css'],
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: AnchorComponent, useExisting: forwardRef(() => NavItemComponent) }]
})
export class NavItemComponent extends AnchorComponent {
  @Input() menu = false;
  @Input() symbols = false;
}
