import { Component } from '@angular/core';
import { version } from '@environment/version';

/**
 * Angular component to render the footer.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  /**
   * Attribute of the revision to render in the footer.
   */
  revision = version.revision;
}
