import { Component, Input } from '@angular/core';

/**
 * Angular component to render a simple anchor component.
 */
@Component({ selector: 'app-anchor', template: '<a anchor [attr.aria-label]="alt"><ng-content></ng-content>{{label}}</a>' })
export class AnchorComponent {
    /**
     * {@link Input} parameter of the url to set as src attribute
     */
    @Input() link!: string;

    /**
     * {@link Input} parameter of the name of the anchor
     */
    @Input() label!: string;
    @Input() alt?: string;

    /**
     * {@link Input} parameter of the icon of the anchor
     */
    @Input() icon!: string;

    /**
     * {@link Input} parameter as indicatior if this anchor is a external resource.
     * This Attribute will be used to set target and rel attribute to the anchor element.
     */
    @Input() external?: boolean;
}
