<mat-sidenav-container class="bg-gradient">
  <mat-sidenav role="navigation">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class="bg-transparent">
    <app-header></app-header>
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer class="bg-transparent"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>